<template>
    <div>
        <LegalWarningSection title='DATOS IDENTIFICATIVOS'>
            <p>
                En cumplimiento con el deber de información recogido en el artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, a continuación, se reflejan los siguientes datos:
            </p>
            <p>
                El titular de esta website es “{{fullName}}”, {{registerData}} Correo electrónico de contacto {{contactMail}}.
            </p>
            <p>
                La información contenida en {{webs}} constituye un servicio de información de los diversos servicios y productos que ofrece “{{fullName}}” (en adelante “{{shortName}}”) dentro del ámbito de la programación, consultoría y otras actividades relacionadas con la informática.
            </p>
        </LegalWarningSection>
        <LegalWarningSection title='ACEPTACIÓN DE LAS CONDICIONES DE USO'>
            <p>
                El acceso a las Webs {{webs}} exige la aceptación de las condiciones de uso que en cada momento se encuentren vigentes en esta Web.            
            </p>
        </LegalWarningSection>
        <LegalWarningSection title='PROPIEDAD INTELECTUAL'>
            <p>
                El código fuente, los diseños gráficos, las imágenes, las fotografías, las animaciones, el software, los textos, así como la información y los contenidos que se recogen en {{webs}} están protegidos por la legislación española sobre los derechos de propiedad intelectual e industrial a favor de “{{shortName}}”, y no se permite, la reproducción total o parcial de ésta Web, ni su tratamiento informático, comunicación pública, distribución, difusión, modificación, transformación o descompilación, sin el permiso previo y por escrito de su titular, “{{shortName}}” de conformidad con lo dispuesto en los artículos 8 y 32 párrafo segundo de la Ley de Propiedad Intelectual.          
            </p>
            <p>
                El usuario, única y exclusivamente podrá utilizar el material que aparezca en esta Web para su uso personal y privado, quedando prohibida su utilización con fines comerciales o para incurrir en actividades ilícitas. Todos los derechos derivados de la propiedad intelectual están expresamente reservados por “{{shortName}}”.
            </p>
            <p>
                “{{shortName}}” velará por el cumplimiento de las anteriores condiciones y para la debida utilización de los contenidos presentados en su portal, ejercitando todas las acciones civiles y penales que le correspondan en el caso de infracción o incumplimiento de estos derechos por parte del usuario.
            </p>
        </LegalWarningSection>
        <LegalWarningSection title='PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL'>
            <p>
                El acceso a la página Web o su uso no implica una recogida de datos personales del usuario por parte de “{{shortName}}”. No obstante, se comunica que, en el caso de que “{{shortName}}” solicite datos personales, se informará debidamente al usuario conforme a lo establecido en el Reglamento General de Protección de Datos y demás normativa que resulte de aplicación relativa a la protección de datos.
            </p>
            <p>
                Los Datos Personales que recoge “{{shortName}}” en los distintos tipos de formularios serán tratados con la finalidad de dar respuesta a la solicitud realizada por el interesado, así como para el mantenimiento de la relación que en su caso se establezca, y la gestión, administración, información, prestación y mejora de los servicios que decida contratar.
            </p>
            <p>
                Para una información más detallada en lo relativo a la Protección de Datos, deberá acceder a nuestra “Política de Privacidad”.
            </p>
            <p>
                “{{shortName}}” ha tomado las medidas de índole técnica y organizativa necesarias para garantizar la seguridad de los datos y evitar su alteración, pérdida, tratamiento o acceso no autorizado, teniendo en cuenta el estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que se encuentran expuestos, ya provengan de la acción humana o del medio físico o natural.
            </p>
        </LegalWarningSection>
        <LegalWarningSection title='REGISTRO Y CONTRASEÑAS DE ACCESO'>
            <p>
                “{{shortName}}” proporciona de forma confidencial a cada uno de sus clientes un nombre de usuario y una contraseña de acceso, la cual es única, personal e intransferible, siendo condición necesaria para acceder a determinados servicios incluidos en la Web.
            </p>
            <p>
                El usuario se compromete a preservar la confidencialidad de su contraseña, pudiendo solicitar su modificación en caso de pérdida, conocimiento por otras personas o cualquier otra circunstancia que impida o dificulte el normal funcionamiento de su acceso.
            </p>
            <p>
                “{{shortName}}” se reserva el derecho a anular discrecionalmente el acceso de algún usuario cuando concurran circunstancias de las que se presuman utilizaciones ilícitas.
            </p>
            <p>
                El usuario asume la plena responsabilidad y, por tanto, todas las obligaciones que se deriven del uso de los diferentes servicios y productos a los que se accede con clave de acceso o por la utilización que de ellos realicen terceras personas accediendo con la contraseña del usuario.
            </p>
        </LegalWarningSection>
        <LegalWarningSection title='DATOS EN REDES SOCIALES'>
            <p>
                “{{shortName}}” dispone de perfiles en diferentes redes sociales, por lo que hemos de advertir al usuario que todos los datos que publique en estos perfiles serán conocidos por todos aquellos usuarios que también interactúen en ese perfil o que simplemente lo consulten, tengan éstos o no su propio perfil en la red social, ya que todos los perfiles de “{{shortName}}” son públicos. En consecuencia, toda la información y contenidos publicados por el usuario en los perfiles de “{{shortName}}” será objeto de comunicación al resto de los usuarios por la propia naturaleza del servicio. Le recomendamos que consulte las Políticas de Privacidad de las redes sociales antes de utilizar dichos servicios.
            </p>
            <p>
                En el caso de que el usuario envíe información de cualquier tipo a “{{shortName}}”, sobre todo en el caso de datos de terceros, a través de las redes sociales, éste declara y garantiza que está legitimado a enviar dicha información y que no infringe ningún derecho de propiedad intelectual, de marca, de patente, secreto comercial, o cualquier otro derecho de tercero, que la información no tiene carácter confidencial y que no daña u ofende a terceros.
            </p>
            <p>
                “{{shortName}}” sólo es responsable y garantiza la confidencialidad, seguridad y tratamiento de los datos conforme a la legislación vigente, respecto a los datos que recoja directamente del usuario, no teniendo ningún tipo de responsabilidad respecto de los tratamientos y posteriores usos que la red social pudiera efectuar.
            </p>
        </LegalWarningSection>
        <LegalWarningSection title='LIMITACIÓN DE LA RESPONSABILIDAD'>
            <p>
                “{{shortName}}” no se responsabiliza de los posibles daños o perjuicios que puedan suceder como consecuencia del uso de los contenidos de la Web, siendo de exclusiva responsabilidad del usuario que acceda a los mismos.
            </p>
            <p>
                En el mismo sentido, “{{shortName}}” no se responsabiliza de los posibles perjuicios que se puedan derivar del uso de una versión no actualizada o defectuosa del navegador, de interrupciones en la conexión que se produzcan durante la transmisión de datos, virus informáticos, averías o desconexiones en el funcionamiento del sistema telemático, de bloqueos causados por deficiencias o sobrecargas de las líneas telefónicas, así como de daños provocados por terceras personas mediante intromisiones no autorizadas.
            </p>
            <p>
                La Web de “{{shortName}}” contiene enlaces a otras páginas Web que pueden resultar de interés para los usuarios. “{{shortName}}” no asume ninguna responsabilidad sobre estos enlaces, no pudiéndose garantizar el cumplimiento de Políticas de Privacidad adecuadas, motivo por el cual el usuario accede al contenido de las referidas páginas Web en las condiciones de uso que se fijen en las mismas y bajo su exclusiva responsabilidad.
            </p>
            <p>
                “{{shortName}}” no se hace responsable del incumplimiento de cualquier norma aplicable en que pueda incurrir el usuario en su acceso a las Webs {{webs}} y/o en el uso de las informaciones contenidas en ella.
            </p>
            <p>
                “{{shortName}}” se reserva el derecho a interrumpir o denegar discrecionalmente, en cualquier momento y sin necesidad de previo aviso, el acceso de cualquier usuario a los contenidos de acceso restringido, cuando concurran alguna de les circunstancias descritas.
            </p>
        </LegalWarningSection>
        <LegalWarningSection title='ENLACES'>
            <p>
                Todos los enlaces o links, hipertextual, framing o cualquier otro tipo de conexión virtual a través de redes de telecomunicación desde cualquier sitio Web a nuestra página Web, deberá ser solicitado y autorizado previamente y por escrito por “{{shortName}}”, y en su defecto los enlaces que se establezcan a esta Web, deberán hacerse a su página de inicio.
            </p>
            <p>
                Los enlaces que se realicen a páginas Web de terceros tienen únicamente una finalidad orientativa, siendo su objeto el poner a disposición del usuario otras fuentes de información que pudieran ser de su interés.
            </p>
            <p>
                “{{shortName}}” procura revisar periódicamente el contenido de sus enlaces. No obstante, es imposible conocer en todo momento el contenido concreto de los enlaces propuestos. Derivado de ello, solicitamos la colaboración de cualquier usuario que acceda a los referidos contenidos en el supuesto que sus contenidos pudieren ser contrarios a la legalidad vigente, moral u orden público, poniéndolo en conocimiento a través del siguiente correo electrónico {{contactMail}}.
            </p>
        </LegalWarningSection>
        <LegalWarningSection title='RESPONSABILIDAD'>
            <p>
                En definitiva, el usuario es el único responsable del uso que realice de los servicios, contenidos, enlaces (links) e hipertextos incluidos en las Webs {{webs}}.
            </p>
        </LegalWarningSection>
        <LegalWarningSection title='POLITICA DE PRIVACIDAD'>
            <p>
                Los datos de carácter personal obtenidas mediante los diferentes formularios de registro están sujetos a los términos recogidos en nuestra Política de Privacidad.
            </p>
        </LegalWarningSection>
        <LegalWarningSection title='RESERVA'>
            <p>
                “{{shortName}}” se reserva la facultad en todo momento, sin necesidad de previo aviso, de efectuar modificaciones y actualizaciones de la información contenida en su Web o en su configuración y presentación. Asimismo, se reserva el derecho a suspender temporalmente, y sin que sea necesario previo aviso, la accesibilidad a la Web por razones de eventual necesidad y para efectuar operaciones de mantenimiento, reparación o mejora.
            </p>
            <p>
                “{{shortName}}” se reserva el derecho a incluir o retirar total o parcialmente de la Web, cualquier información a su exclusiva discreción.
            </p>
        </LegalWarningSection>
        <LegalWarningSection title='LEY APLICABLE Y JURISDICCIÓN'>
            <p>
                Para todas aquellas cuestiones que se puedan suscitar con motivo de interpretación, ejecución o eventual incumplimiento de las presentes condiciones de utilización, los usuarios, con renuncia a su propio fuero, y con independencia del lugar donde se suscite cualquier disputa, se someterán expresamente a la competencia y jurisdicción de los juzgados y tribunales de Barcelona.
            </p>
            <p>
                Las presentes condiciones se regirán, en todo caso, por la legislación española.
            </p>
        </LegalWarningSection>
        <br/>
        <p>
            "{{fullName}}" <br/> Todos los derechos reservados
        </p>
    </div>
</template>

<script>
import LegalWarningSection from "./LegalWarningSection.vue";
import { mapState } from "vuex";

export default {
    name:'LegalWarning',
    components:{
        LegalWarningSection,
    },
    computed:{
        ...mapState("legal",["fullName","shortName","registerData","target","webs","product","contactMail","formToUnsubscribe","address","vatCode"])
    }
}
</script>

<style lang="scss" scoped>

</style>