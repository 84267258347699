import { leads as api_leads } from "@/api";

// Types
export const ADD_LEAD_BEGIN = "ADD_LEAD_BEGIN";
export const ADD_LEAD_ERROR = "ADD_LEAD_ERROR";
export const ADD_LEAD_SUCCESS = "ADD_LEAD_SUCCESS";

// initial state
// shape: [{ code, name }]
const state = () => ({
  error: false,
  loading: false,
  success: false,
})

// getters
const getters = {
    loading: state => state.loading,
    error: state => state.error,
    success: state => state.success,
}

// actions
const addLead = async ({ commit }, data) => {
  commit(ADD_LEAD_BEGIN);
  try {
    const response = await api_leads.addLead(data)
    if(Object.keys(response.data).includes('ok'))
        commit(ADD_LEAD_SUCCESS);
    else
        commit(ADD_LEAD_ERROR, response.data);
    return response;
  } catch (error) {
    // TODO: Properly handle call errors
    commit(ADD_LEAD_ERROR, {'server_error':true});
    throw error;
  }
};

const actions = {
  addLead
}

// mutations
const mutations = {
  [ADD_LEAD_BEGIN](state) {
    state.success = false;
    state.loading = true;
    state.error = false;
  },
  [ADD_LEAD_SUCCESS](state) {
    state.success = true;
    state.error = false;
    state.loading = false;
  },
  [ADD_LEAD_ERROR](state, data) {
    state.success = false;
    state.error = data;
    state.loading = false;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}