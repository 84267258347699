import { client } from "./client";

const ROUTES = {
  addLead: () => `/add_lead/`,
};

export const leads = {
  addLead: (data) => {
    return client.post(ROUTES.addLead(),data);
  },
};
