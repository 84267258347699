import axios from "axios";

// const CSRF_COOKIE_NAME = "csrftoken";
// const CSRF_HEADER_NAME = "X-CSRFToken";

const baseURL = "https://api.inmatic.ai/api_crm";
//const baseURL = "http://localhost:8895/api_crm";

let client = axios.create({
    baseURL,
    // xsrfCookieName: CSRF_COOKIE_NAME,
    // xsrfHeaderName: CSRF_HEADER_NAME
  });

export {client, };
