<template>
  <div>
    <section id="hero">
        <v-row no-gutters>
          <!--:min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'" TODO: revisar application.top enlloc de fixar padding a v-content -->
          <v-img
            min-height="100vh"
            src="https://inmatic-newsletter.s3-eu-west-1.amazonaws.com/Coneptum+portada.jpg"
          >
            <v-theme-provider dark>
              <v-container fill-height>
                <v-row
                  align="center"
                  class="white--text mx-auto"
                  justify="center"
                >
                  <v-col
                    class="white--text text-center"
                    cols="12"
                    tag="h1"
                  >        

        
          <span
                      :class="[$vuetify.breakpoint.smAndDown ? 'display-2': 'display-4']"
                      class="primary--text" 
                    >
                      Tu fábrica de software
                    </span>
        <!--
       <br>
                    <span
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                      class="font-weight-light" style="color:#65A5F5;"
                    >
                      Tu robot contable
                    </span>
       <br>
                    <span
                    :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
                      class="font-weight-light" style="color:#65A5F5;"
                    >
                      Contabiliza facturas a la velozidad de la luz
                    </span>-->


                    

                    

                  </v-col>

                  <v-btn
                    class="align-self-end primary--text"
                    fab
                    outlined
                    @click="$vuetify.goTo('#what-is-it')"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-theme-provider>
          </v-img>
        </v-row>
      </section>

<section id="what-is-it">
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3 primary--text">¿Qué es Coneptum?</h2>

          <v-responsive
            class="mx-auto mb-8"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-responsive
            class="mx-auto title font-weight-light mb-8"
            max-width="720"
          >
            <strong>Coneptum</strong> es una empresa de tecnología con más de 10 años de experiencia. Contamos con un <strong>equipo multidisciplinar</strong> con un denominador común que es la pasión por la <strong>tecnologia</strong>. Con un espíritu joven y dinámico encabeza y <strong>lidera</strong> las últimas <strong>tecnologías del mercado</strong>
          </v-responsive>

          <v-avatar
            class="elevation-12 mb-12"
            size="256"
          >
            <v-img src="https://inmatic-newsletter.s3-eu-west-1.amazonaws.com/Coneptum+teclado.jpg"></v-img>
          </v-avatar>

          <div></div>

          <v-btn
            color="primary"
            @click="$vuetify.goTo('#contact')"
            large
          >
            <span class="white--text text--darken-1 font-weight-bold">
              Contáctanos!
            </span>
          </v-btn>
        </v-container>

        <div class="py-12"></div>
      </section>
      
      <section
        id="how-it-works"
        class="grey lighten-3"
      >
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">¿Qué hacemos?</h2>

          <v-responsive
            class="mx-auto mb-12"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col
              v-for="({ icon, title, text }, i) in features"
              :key="i"
              cols="12"
              md="4"
            >
              <v-card
                class="py-12 px-4"
                color="grey lighten-5"
                flat height="100%"
              >
                <v-theme-provider dark>
                  <div>
                    <v-avatar
                      color="primary"
                      size="88"
                    >
                      <v-icon
                        large
                        v-text="icon"
                      ></v-icon>
                    </v-avatar>
                  </div>
                </v-theme-provider>

                <v-card-title
                  class="justify-center font-weight-black text-uppercase subtitle-2"
                  v-text="title"
                ></v-card-title>

                <v-card-text
                  class="subtitle-1"
                  v-text="text"
                >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>

      <section id="stats">
        <v-parallax
          :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
          src="https://inmatic-newsletter.s3-eu-west-1.amazonaws.com/fons+blau.PNG"
        >
          <v-container fill-height>
            <v-row class="mx-auto">
              <v-col
                v-for="[value, title] of stats"
                :key="title"
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <div
                    class="display-3 font-weight-black mb-4"
                    v-text="value"
                  ></div>

                  <div
                    class="title font-weight-regular text-uppercase"
                    v-text="title"
                  ></div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-parallax>
      </section>

      <section id="features">
        <div class="py-12"></div>

        <v-container>
          <h2 class="display-1 font-weight-bold mb-3 text-uppercase text-center">InMatic, tu robot contable</h2>

          <v-responsive
            class="mx-auto mb-12"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col
              v-for="({ src, text, title }, i) in articles"
              :key="i"
              cols="12"
              md="4"
            >
              <v-img
                :src="src"
                class="mb-4"
                height="275"
                max-width="100%"
              ></v-img>

              <h3
                class="font-weight-black mb-4 text-uppercase"
                v-text="title"
              ></h3>

              <div
                class="title font-weight-light mb-5"
                v-text="text"
              ></div>

            </v-col>
            <v-col cols="4"/>
            <v-col cols="4">
              <v-btn color="primary" href="https://inmatic.ai">
                Más información
              </v-btn>
            </v-col>
            <v-col cols="4"/>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>

      <v-sheet
        id="contact"
        color="#333333"
        dark
        tag="section"
        tile
      >
        <div class="py-12"></div>

        <v-container>
          <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Contáctanos</h2>

          <v-responsive
            class="mx-auto mb-12"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <LeadForm :tags="tags_lead"></LeadForm>
        </v-container>

        <div class="py-12"></div>
      </v-sheet>
      
  </div>
</template>

<script>
// @ is an alias to /src
import LeadForm from "@/components/LeadForm.vue";

export default {
  name: 'Home',
  components: {
    LeadForm,
  },
  data () {
      return {
        articles: [
          {
            src: 'https://inmatic-newsletter.s3-eu-west-1.amazonaws.com/InMatic_portail_manos.jpg',
            title: 'Sin instalación previa',
            text: 'InMatic, a diferencia de otros programas y OCR’s, es un programa que no requiere instalación previa y esto hace que sea rentable desde el primer minuto.',
          },
          {
            src: 'https://inmatic-newsletter.s3-eu-west-1.amazonaws.com/InMatic_aumenta_+productividad.jpg',
            title: 'Aumenta la productividad',
            text: 'InMatic contabiliza las facturas, previamente escaneadas y subidas en el programa, en unos segundos. Este ahorro de tiempo hace que la productividad de tu equipo sea increíblemente mayor.',
          },
          {
            src: 'https://inmatic-newsletter.s3-eu-west-1.amazonaws.com/InMatic_robot_mano.jpg',
            title: 'Inteligencia artificial',
            text: 'Gracias a las últimas técnicas de Inteligencia Artificial, InMatic aprende de manera autónoma a partir de cada nueva factura que procesa. Esto hace que su precisión aumente de manera continuada.',
          },
        ],
        features: [
          {
            icon: 'mdi-web',
            title: '1. Desarrollo web',
            text: 'Implementamos aplicaciones web amigables usando tecnologías punteras que nos permiten entregar frontends amigables y ergonómicos, adaptados a las necesidades e imagen del cliente multiplicando su valor gracias a un backend de alto rendimiento desplegado en el cloud',
          },
          {
            icon: 'mdi-brain',
            title: '2. Inteligencia artificial',
            text: 'Gracias a las últimas técnicas de Inteligencia Artificial, diseñamos e implementamos productos dotados con funcionalidades de procesamiento del lenguaje natural y visión artificial. Con esto se abre un nuevo abanico de posibilidades dónde los ordenadores aprenden de los datos recopilados.',
          },
          
          {
            icon: 'mdi-database',
            title: '3. IoT i Big Data',
            text: 'Proveemos a nuestros clientes del software i la infraestructura clave para capturar, procesar y sacar valor de los millones de datos generados en verticales cómo el de Telecomunicaciones o el de Industria. Acompañamos estas soluciones de frontends analíticos que permiten sacar el máximo provecho de los datos recopilados.',
          },
        ],
        stats: [
          ['+20', 'clientes satisfeschos'],
          ['10', 'años de experiencia'],
          ['5', 'proyectos  basados en AI'],
          ['+50', 'proyectos realizados con éxito'],
        ],
        tags_lead: ['home_coneptum'],
      }
    }
}
</script>
