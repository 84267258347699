<template>
    <div>
        <span class="title font-weight-light grey--text text--lighten-1 text-center pl-1 pr-1">
            |
        </span>
        <span>
            <a @click="emitChange(true)" class="title font-weight-light grey--text text--lighten-1 text-center text-decoration-underline">{{title}}</a>
        </span>
        <v-dialog
            :value="myValue"
            @input="emitChange"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
            <v-toolbar
                dark
                color="primary"
            >
                <v-btn
                    icon
                    dark
                    @click="emitChange(false)"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{title}}</v-toolbar-title>
            </v-toolbar>
            <div class="pa-10">
                <slot></slot>
            </div>
            

            </v-card>
        </v-dialog>

    </div>
</template>

<script>
export default {
    name:'LegalDialog',
    props:{
        title:String,
        value:Boolean,
    },
    data(){
        return {
            myValue:false
        }
    },
    created(){
        this.myValue=this.value
    },
    methods:{
        emitChange(val){
            this.myValue=val
            this.$emit('input',val)
        }
    },
    watch:{
        value(val){
            this.myValue=val
        }
    }
}
</script>

<style lang="scss" scoped>

</style>