import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
    menu:[
      {name:'Inicio',goTo:'#hero'},
      {name:'¿Qué es Coneptum?',goTo:'#what-is-it'},
      {name:'¿Qué hacemos?',goTo:'#how-it-works'},
      {name:'InMatic',goTo:'#features'},
      {name:'Contacto',goTo:'#contact'},
    ],
    drawer:false,
  })

const getters = {
  getField
}

const mutations = {
  updateField
}

export default {
    state,
    namespaced:true,
    getters,
    mutations,
}