<template>
  <v-app>
    <ScrollHandler/>
    <AppBar/>
    <Drawer/>
    <v-content style="padding:0px;">
      <router-view/>
      <CookieConsent>
        <template slot="message">
          <span>Este sitio web utiliza cookies para mejorar su experiencia de usuario.</span>
          <span class="ml-2"><a @click="cookiesPolicy=true">Política de cookies</a></span>
        </template>
        <template slot="button">
          <v-btn color="primary" class="ml-2">Aceptar</v-btn>
        </template>
      </CookieConsent>  
    </v-content>

    <v-footer
      class="justify-center"
      color="#292929"
      height="100"
    >
      <div class="title font-weight-light grey--text text--lighten-1 text-center">
        &copy; {{ (new Date()).getFullYear() }} — Coneptum S.L.
      </div>
      <LegalDialog title="Aviso legal" v-model="legalWarningDialog">
        <LegalWarning/>
      </LegalDialog>
      <LegalDialog title="Política de privacidad" v-model="showPrivacyPolicy">
        <PrivacyPolicy/>
      </LegalDialog>
      <LegalDialog title="Política de cookies" v-model="cookiesPolicy">
        <CookiesPolicy/>
      </LegalDialog>     
    </v-footer>
  </v-app>
</template>

<script>
import PrivacyPolicy from "@/components/LegalContent/PrivacyPolicy.vue"
import CookiesPolicy from "@/components/LegalContent/CookiesPolicy.vue"
import LegalDialog from "@/components/LegalContent/LegalDialog.vue"
import LegalWarning from "@/components/LegalContent/LegalWarning.vue"
import { mapFields } from 'vuex-map-fields';
import CookieConsent from 'vue-cookieconsent-component'
import ScrollHandler from "@/components/ScrollHandler.vue"
import AppBar from "@/components/AppBar.vue"
import Drawer from "@/components/Drawer.vue"


export default {
  name: 'App',
  components: {
    PrivacyPolicy,
    CookiesPolicy,
    CookieConsent,
    LegalDialog,
    LegalWarning,
    ScrollHandler,
    AppBar,
    Drawer,
  },
  methods:{
    privacyPolicy(){
      console.log('hello')
    }
  },
  data(){
    return {
      cookiesPolicy:false,
      legalWarningDialog:false,
    }
  },
  computed:{
    ...mapFields('legal',['showPrivacyPolicy']),
  },
  watch: {
      $route: {
          immediate: true,
          handler(to) {
              document.title = to.name || 'InMatic';
          }
      },
  }
};
</script>
<style lang="sass">
  @import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent";
  @import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent-bottom";
  @import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent-transition";
</style>
