<template>
  <h4>
      {{text}}
  </h4>
</template>
<style>
</style>

<script>

export default {
  name: 'PrivacyPolicySubTitle',
  props:{
    'text':String
  }
}
</script>
