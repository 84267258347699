<template>
  <div>
    <p class="text-decoration-underline pl-2">
    {{rightTitle}}
    </p>
    <div class="pl-4">
      <slot>
      </slot>
    </div>
  </div>
  

</template>
<style>
</style>

<script>

export default {
  name: 'PrivacyPolicyRight',
  props:{
    'rightTitle':String
  }
}
</script>
