<template>
  <v-theme-provider light>
    <v-row no-gutters>
      <v-col cols="12">
          <v-text-field
          flat
          label="Nombre y apellidos*"
          solo
          :disabled="success"
          :class="{'error_empty': error &&  Object.keys(error).includes('full_name')}"
          v-model="full_name"
          ></v-text-field>
      </v-col>
      <div v-if="Object.keys(error).includes('email') && error.email[0]=='Enter a valid email address.'" style="color:red">Por favor, introduzca un email correcto</div>
      <v-col cols="12">
          <v-text-field
          flat
          label="Email*"
          solo
          :disabled="success"
          :class="{'error_empty': error &&  Object.keys(error).includes('email')}"
          v-model="email"
          ></v-text-field>
      </v-col>

      <v-col cols="12">
          <v-text-field
          flat
          label="Teléfono de contacto*"
          solo
          :class="{'error_empty': error &&  Object.keys(error).includes('phone')}"
          :disabled="success"
          v-model="phone"
          ></v-text-field>
      </v-col>

      <v-col cols="12">
          <v-text-field
          flat
          label="Empresa*"
          solo
          :disabled="success"
          :class="{'error_empty': error &&  Object.keys(error).includes('company')}"
          v-model="company"
          ></v-text-field>
      </v-col>

      <v-col cols="12">
          <v-textarea
          flat
          label="Mensaje"
          solo
          :disabled="success"
            :class="{'error_empty': error &&  Object.keys(error).includes('message')}"
        v-model="message"
          ></v-textarea>
      </v-col>
      <v-col cols="12" class="mt-0">
        <v-checkbox
              v-model="privacyAccepted"
              color="primary"
              dark
              :disabled="success"
              hide-details
              class="mt-0"
            >
            <template slot="label">
              <span class="font-weight-light grey--text text--lighten-1 text-center pl-1 pr-1">
                He leído y acepto la
              </span>
              <span>
                  <a @click="showPrivacyPolicy=true" class="font-weight-light grey--text text--lighten-1 text-center text-decoration-underline">Política de privacidad</a>
              </span>
              
            </template>
        </v-checkbox>
      </v-col>
      <v-col cols="12" class="pa-2 font-weight-light grey--text text--lighten-1 text-justify text-caption">
        Le informamos que los datos que voluntariamente nos proporcione en el presente formulario serán tratados por “{{fullName}}” con la finalidad de dar respuesta a su solicitud. Sus datos no serán cedidos a terceros, salvo cuando sea indispensable para la prestación del servicio u obligaciones legales. Puede ejercer sus derechos de acceso, rectificación, limitación, portabilidad, oposición y supresión de los datos a través del correo electrónico {{contactMail}}{{formToUnsubscribe}}. {{moreInfo}}
      </v-col>
      <v-col cols="12" class="mt-0 mb-10">
        <v-checkbox
              v-model="adsAccepted"
              color="primary"
              hide-details
              class="mt-0"
              :disabled="success"
              dark
            >
            <template slot="label">
              <span class="font-weight-light grey--text text--lighten-1 text-center pl-1 pr-1">
                Deseo recibir comunicaciones comerciales relacionadas con sus servicios y productos
              </span>
            </template>
        </v-checkbox>
      </v-col>
      <v-expand-transition>
        <v-col v-show="!success"
            class="mx-auto"
            cols="auto"
        >
            <v-btn
              color="accent"
              :loading="loading"
              x-large @click="add_lead()"
              dark
              
            >
            Enviar
            </v-btn>
        </v-col>        
      </v-expand-transition>
      <v-expand-transition>
        <v-col cols="12" v-show="success">
          <v-alert
          dense
          outlined
          prominent
          text
          type="success"
          >Solicitud de contacto recibida. Nos podremos en contacto con usted en la mayor brevedad posible.
          </v-alert>
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <v-col cols="12" class="pt-8" v-show="privacyWarning">
          <v-alert
          dense
          outlined
          prominent
          text
          type="error"
          >Para poder enviar la solicitud de contacto debe aceptar la política de privacidad
          </v-alert>
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <v-col cols="12" class="pt-8" v-show="error">
          <v-alert
          dense
          outlined
          prominent
          text
          type="error"
          >Por favor, revise los errores del formulario
          </v-alert>
        </v-col>
      </v-expand-transition>
    </v-row>
  </v-theme-provider>
</template>
<style>
.error_empty .theme--light.v-label{
  color: red
}
.error_empty .theme--light.v-input:not(.v-input--is-disabled) input{
  color: red !important
}
</style>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { mapFields } from 'vuex-map-fields';


  export default {
    name: 'LeadForm',
    props: {
      tags: {
        type: Array,
        default: function () {
          return [];
        },
      },
    },
    data: () => ({
      full_name:null,
      email:null,
      phone:null,
      company:null,
      message:null,
      privacyAccepted:false,
      adsAccepted:false,
      privacyWarning:false,
    }),
    methods: {
      add_lead(){
        if (this.privacyAccepted){
          this.privacyWarning=false
          this.addLead({full_name:this.full_name, email:this.email, phone:this.phone, company:this.company, message:this.message, tags:this.tags, ads_accepted:this.adsAccepted})
        }else{
          this.privacyWarning=true
        }
      },
      ...mapActions({
        addLead: "leads/addLead",
      }),
    },
    computed: {
      ...mapGetters({
        loading: 'leads/loading',
        error: 'leads/error',
        success: 'leads/success',
      }),
      ...mapFields('legal',['showPrivacyPolicy']),
      ...mapState("legal",["fullName","shortName","registerData","target","webs","product","contactMail","formToUnsubscribe","address","moreInfo"])
    },
  }
</script>
