<template>
    <div>

    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapFields } from 'vuex-map-fields';


export default {
  created () {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  },
  methods: {
    handleScroll() {
      this.scroll=window.scrollY
    }
  },
  computed: {
      ...mapFields('scroll',['scroll']),
  }
}
</script>

<style lang="scss" scoped>

</style>