<template>
    <div>
        <h3>{{title}}</h3>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name:'LegalWarningSection',
        props:{
            title:String,
        },
    }
</script>

<style lang="scss" scoped>

</style>