export const SET_SHOW_PRIVACY_POLICY = "SET_SHOW_PRIVACY_POLICY";

const WEBS='www.coneptum.com'
//const WEBS='www.coneptum.com'
import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
    fullName: 'CONEPTUM, S.L.',
    shortName: 'CONEPTUM',
    vatCode:'B65946956',
    registerData: 'con domicilio en C/Sant Agustí, 3-5, Planta 2, Puerta B, 08012 de Barcelona, con número de NIF: B65946956, inscrita en el Registro Mercantil de Barcelona Tomo: 43547, Folio: 67, Hoja: 431925, Inscripción: 1.',
    target: 'los usuarios de las Webs '+WEBS,
    webs: WEBS,
    product: 'los diversos productos y servicios que se ofrecen dentro del ámbito de la programación, consultoría y otras actividades relacionadas con la informática',
    contactMail:'admin@coneptum.com',
    //formToUnsubscribe:' o mediante el siguiente formulario web www.coneptum.com/formulario_derechos',
    formToUnsubscribe:'',
    address:'C/Sant Agustí, 3-5, Planta 2, Puerta B, 08012 de Barcelona',
    moreInfo:'Más información en www.coneptum.com.',
    cookies:[
      {
        name:"cookieconsent_status",
        host:'coneptum.com',
        purpose:"Consentimiento",
        persistence:'365 días',
      },
      {
        name:"cookieconsent_status2",
        host:'coneptum.coma',
        purpose:"Consentimiento",
        persistence:'365 días',
      },
      {
        name:"<pending>",
        host:'www.coneptum.com',
        purpose:"Consentimiento",
        persistence:'365 días',
      },
      {
        name:"XSRF-TOKEN",
        host:'inmatic.auth.eu-west-1.amazoncognito.com ',
        purpose:"Autenticación",
        persistence:'365 días',
      },
    ],
    showPrivacyPolicy:false,
  })

const getters = {
  getField
}

const mutations = {
  updateField
}

const actions = {
  setShowPrivacyPolicy({ commit }, value){
    commit(SET_SHOW_PRIVACY_POLICY,value)
  }
}

export default {
    state,
    namespaced:true,
    getters,
    mutations,
    actions,
}