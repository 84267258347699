<template>
  <div>
    <p>
      Una cookie es un fichero que se descarga en el ordenador/smartphone/tablet del usuario al acceder a determinadas páginas web para almacenar y recuperar información sobre la navegación que se efectúa desde dicho equipo, pero no nos proporcionan información ni sobre su nombre, ni sobre cualquier otro dato de carácter personal suyo, constando únicamente a efectos de funcionamiento, seguridad y estadística. Las cookies que utilizamos no pueden leer datos de su ordenador ni leer datos de otras cookies. Puede configurar su navegador para que le avise en pantalla que va a recibir una cookie. Si el usuario decide no aceptar cookies, esto no impedirá que pueda acceder a la información de {{webs}}.    
    </p>
    <p>
      “{{fullName}}” utiliza cookies propias para gestionar los diferentes servicios que ofrece al usuario, y en función de su finalidad podemos distinguir entre:
    </p>
    <p>
      Cookies de sesión: Son un tipo de cookies diseñadas para recabar y almacenar datos cuando el usuario inicia sesión en el área privada de {{webs}}. Esta cookie caduca al finalizar la sesión, borrándose de su ordenador.
    </p>
    <p>
      Cookies persistentes: Son un tipo de cookies en el que los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.
    </p>
    <p>
      Cookies técnicas: Son aquéllas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de vídeos o sonido o compartir contenidos a través de redes sociales.
    </p>
    <p>
      Cookies de personalización: Son aquéllas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.
    </p>
    <p>
      Cookies de análisis: Son aquéllas que permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.    
    </p>
    <p>
      Según el Grupo de Trabajo del Artículo 29, en su Dictamen 4/2012, ha interpretado que entre las cookies exceptuadas del cumplimiento de las obligaciones establecidas en el artículo 22.2 de la LSSI, están las utilizadas para permitir únicamente la comunicación entre el equipo del usuario y la red, y aquellas utilizadas estrictamente para prestar un servicio expresamente solicitado por el usuario, estando aquellas que tienen por finalidad:
    </p>
    <ul>
      <li>Cookies de «entrada del usuario»</li>
      <li>Cookies de autenticación o identificación de usuario (únicamente de sesión)</li>
      <li>Cookies de seguridad del usuario (Por ejemplo para detectar intentos erróneos y reiterados de conexión a un sitio web)</li>
      <li>Cookies de sesión de reproductor multimedia</li>
      <li>Cookies de sesión para equilibrar la carga</li>
      <li>Cookies de personalización de la interfaz de usuario</li>
      <li>Cookies de complemento (plug-in) para intercambiar contenidos sociales</li>
    </ul>
    <p>
      Las cookies de sesión y de entrada de usuario se suelen utilizar para rastrear las acciones del usuario al rellenar los formularios en línea en varias páginas, o como cesta de la compra para hacer el seguimiento de los artículos que el usuario ha seleccionado al pulsar un botón.    
    </p>
    <p>
      Si se desactiva la aceptación de las cookies estrictamente necesarias para la prestación de determinados servicios solicitados expresamente por el usuario, no podrá consultar y/o recibir correctamente los contenidos y servicios de {{webs}}.
    </p>
    <p>
      A continuación se relacionan las cookies utilizadas en {{webs}}:
    </p>
    <template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Nombre
          </th>
          <th class="text-left">
            Dominio/Host
          </th>
          <th class="text-left">
            Finalidad
          </th>
          <th class="text-left">
            Persistencia
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="cookie in cookies"
          :key="cookie.name"
        >
          <td>{{ cookie.name }}</td>
          <td>{{ cookie.host }}</td>
          <td>{{ cookie.purpose }}</td>
          <td>{{ cookie.persistence }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <p>
    “{{fullName}}” también utiliza las cookies analíticas del servicio de Google Analytics para el análisis de nuestro sitio web. Estas cookies se utilizan para recoger información sobre el uso de nuestro sitio web por parte de los visitantes, utilizando la información para facilitar y mejorar la navegación. La desactivación de estas cookies no afectará a la funcionalidad de {{webs}}.
  </p>
  <p>
    Google Analytics es la herramienta de analítica de Google que ayuda a los sitios web y a los propietarios de aplicaciones a entender el modo en que sus visitantes interactúan con éstas. Puede utilizar un conjunto de cookies para recopilar información e informar de las estadísticas de uso de los sitios web sin identificar personalmente a los visitantes de Google. Más información sobre las cookies de Google Analytics e información sobre la privacidad en https://developers.google.com/analytics/resources/concepts/gaConceptsCookies
  </p>
  <p>
    Algunas partes de la Web se pueden conectar con diversas redes sociales como por ejemplo Facebook, Twitter, Google+, Linkedin, etc., por lo que deberá consultar la política de cookies que éstas ofrezcan en cada caso antes de utilizar sus servicios.
  </p>
  <p>
    Puede permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones de su navegador de Internet. A continuación, le indicamos los links de los principales navegadores dónde explican cómo:
  </p>
  <ul>
    <li>Internet Explorer http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies</li>
    <li>Google Chrome https://support.google.com/chrome/answer/95647?hl=es
    </li>
    <li>Google Chrome para Android https://support.google.com/chrome/answer/2392971?hl=es
    </li>
    <li>Mozilla Firefox https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
    </li>
    <li>Safari http://support.apple.com/kb/ph5042
    </li>
    <li>Safari para IOS (iPhone - iPad) http://support.apple.com/kb/ht1677?viewlocale=es_es&locale=es_es
    </li>
  </ul>
  <p>
    Estos navegadores están sometidos a constantes actualizaciones y modificaciones, por lo que no podemos garantizar que se ajusten completamente a la versión de su navegador actual. También es posible que utilice otro navegador no contemplado en estos enlaces, por lo que deberá consultar la ayuda de su navegador para configurar el uso de cookies.      
  </p>
  <p>
    Si quisiese retirar su consentimiento en cualquier momento relacionado con la presente Política de Cookies, deberá eliminar las cookies almacenadas a través de los ajustes y configuraciones de su navegador de Internet.
  </p>
  <p>
    La presente Política de Cookies tiene por finalidad informarle de manera clara y precisa sobre las cookies que se utilizan en {{webs}}. En caso de que quiera más información sobre el uso de las cookies podrá solicitarlo por correo electrónico a {{contactMail}}.
  </p>
    
</template>
  </div>
</template>
<style>
</style>

<script>
import { mapState } from "vuex";
export default {
  name: 'CookiesPolicy',
  components: {
  },
  computed:{
    ...mapState("legal",["fullName","shortName","registerData","target","webs","product","contactMail","formToUnsubscribe","address","cookies"])
  }
}
</script>
