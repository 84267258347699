<template>
    <v-app-bar
      app
      :color="barColor"
      height="100"
      v-if="showBar"
      :flat="flatBar"
    >
      <v-img src="/logo_inmatic.png" contain max-width="80"></v-img>
      <v-toolbar-title class="text-h2 primary--text" >
        Coneptum
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn 
         text
         v-for="(elem, i) in menu"
         :key="i"
          v-bind="elem"
         color="primary" 
         @click="$vuetify.goTo(elem.goTo)"
         class="hidden-sm-and-down"
      >
        {{elem.name}}
      </v-btn>
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer=!drawer"
        :color="burgerColor"
      />
    </v-app-bar>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
export default {    
    'name':'AppBar',
    computed:{
        ...mapFields('scroll',['scroll']),
        ...mapFields('menu',['menu','drawer']),
        flatBar(){
            return this.scroll===0
        },
        barColor(){
            return this.scroll>this.threshold ? "rgba(255,255,255,1.0)":"rgba(0,0,0,0.0)"
        },
        burgerColor(){
            return this.scroll>this.threshold ? "black":"primary"
        },
        showBar(){
            return this.$route.name=='Coneptum'
        }
    },
    data(){
      return {
        threshold:200,
      }
    }
}
</script>

<style lang="scss" scoped>

</style>