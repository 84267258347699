<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    temporary
    right
  >
    <v-list>
      <v-list-item
        v-for="(link, i) in menu"
        :key="i"
        @click="goTo($event, link.goTo)"
      >
        <v-list-item-title v-text="link.name" />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
export default {    
    'name':'AppBar',
    computed:{
        ...mapFields('scroll',['scroll']),
        ...mapFields('menu',['menu','drawer']),
    },
    methods:{
      goTo(event,goTo){
        event.stopPropagation()
        this.$vuetify.goTo(goTo)
        this.drawer=false
      }
    }
}
</script>

<style lang="scss" scoped>

</style>