<template>
  <h3 class="text-decoration-underline">
      {{text}}
  </h3>
</template>
<style>
</style>

<script>

export default {
  name: 'PrivacyPolicyTitle',
  props:{
    'text':String
  }
}
</script>
